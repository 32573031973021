import React from "react"
import Layout from "../components/layout"
import ResourcesGrid from "../components/ResourcesGridComponent"
import "../styles/resources.scss"

const Resources = () => {
  const resources = [
    {
      title: "Cisco Hyperflex to Nutanix Migration",
      imageURL: "/blog-images/pdf-icon.png",
      imageAltText: "",
      tags: ["Nutanix"],
      url:
        "/pdfs/Metsi_-_Cisco_Hyperflex_to_Nutanix_Migration_-_Document_A4_2.pdf",
    },
    {
      title: "Maximising Your Service Now Platform Investment",
      imageURL: "/blog-images/pdf-icon.png",
      imageAltText: "",
      tags: ["Platform Investment"],
      url:
        "/pdfs/Metsi_-_Maximising_Your_ServiceNow_Platform_Investment_v2_1.pdf",
    },
    {
      title: "Migration Case Study",
      imageURL: "/blog-images/pdf-icon.png",
      imageAltText: "",
      tags: ["Case Study"],
      url: "/pdfs/Metsi_Technologies_-_Migration_Case_Study_1.pdf",
    },
  ]

  return (
    <Layout>
      <div className="values-bkg">
        <div className="charity-hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1>Resources & Media</h1>
                  <h2>Read our latest brochures and stay up to date</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="resources">
        <div className="section">
          <div className="outer-container">
            <div className="inner-container">
              <h1>Resources & Media</h1>
              <h2>Stay up to date with all things Metsi</h2>
              <ResourcesGrid resources={resources} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Resources
